/************ TABLE OF CONTENTS ***************
1.  Fonts
2.  Reset
3.  Global
4.  Main Header
5.  Main Slider
6.  Home Page Sections
7.  Main Footer
8.  Services
9.  Contact
10.  Several 

**********************************************/

/*** 
====================================================================
	Fonts
====================================================================
 ***/

@import url('http://fonts.googleapis.com/css?family=Open + Sans:400, 400italic, 600, 300italic, 300');
@import url('http://fonts.googleapis.com/css?family=Droid + Sans:400, 700');
@import url('http://fonts.googleapis.com/css?family=Montserrat:400, 700');

/*** 
====================================================================
	Reset
====================================================================
 ***/
* {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
}

/*** 
====================================================================
	Global Settings
====================================================================
 ***/
body {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	color: #ffffff;
	line-height: 1.7em;
	font-weight: 400;
	background: #ffffff;
	background-image: url('../images/sitebgd.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position-x: left;
	background-position-y: top;
}

a {
	text-decoration: none;
	cursor: pointer;
}

a:hover,
a:focus,
a:visited {
	text-decoration: none;
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	position: relative;
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	margin: 0px;
	background: none;
	line-height: 1.5em;
}

input,
button,
select,
textarea {
	font-family: 'Open Sans', sans-serif;
}

/*::-webkit-input-placeholder { color:rgba(0,0,0,0.80);}
::-moz-placeholder { color:rgba(0,0,0,0.80) ;}
:-ms-input-placeholder { color:rgba(0,0,0,0.80) ;}
input:-moz-placeholder { color:rgba(0,0,0,0.80) ;}*/

p {
	position: relative;
}

.strike-through {
	text-decoration: line-through;
}

.auto-container {
	position: static;
	max-width: 1200px;
	padding: 0px 15px;
	margin: 0 auto;
}

.page-wrapper {
	position: absolute;
	margin: 0 auto;
	top: 160px;
	width: 100%;
	min-width: 300px;
	min-height: 400px;
	overflow: hidden;
}

ul,
li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.center {
	text-align: center;
}

.theme-bg {
	background-color: #fc5a1b;
}

.theme-color {
	color: #fc5a1b;
}

.light-btn {
	position: relative;
	display: inline-block;
	padding: 10px 30px;
	line-height: 26px;
	border: 1px solid transparent;
	background: #fc5a1b;
	margin-bottom: 5px;
	color: #ffffff;
	text-transform: uppercase;
	font-family: 'Droid Sans', sans-serif;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.dark-btn {
	position: relative;
	display: inline-block;
	padding: 5px 20px;
	line-height: 22px;
	font-size: 14px;
	border: 1px solid #ff5700;
	border-radius: 4px;
	background: #232323;
	color: #ff5700;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.light-btn:hover,
.dark-btn:hover {
	background-color: #ff5700;
	border-color: #232323;
	color: #ffffff;
}

.default-btn {
	position: relative;
	display: inline-block;
	line-height: 24px;
	padding: 8px 24px;
	font-size: 14px;
	text-transform: uppercase;
	margin: 0px 10px;
	color: #000000;
	border: 1px solid #dddddd;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.default-btn:hover {
	color: #ffffff !important;
}

.default-btn::before {
	background-color: #3d3d3d;
}

.color-white {
	color: #ffffff !important;
}

.color-grey {
	color: #292929 !important;
}

.no-margin {
	margin: 0px !important;
}

.no-float {
	float: none !important;
}

.skew-lines {
	position: relative;
	padding-left: 80px;
}

.skew-lines:before {
	content: '';
	position: absolute;
	left: 15px;
	top: 8px;
	width: 20px;
	height: 27px;
	background: #ffffff;
	transform: skewX(-35deg);
	-webkit-transform: skewX(-35deg);
	-moz-transform: skewX(-35deg);
	-ms-transform: skewX(-35deg);
	-o-transform: skewX(-35deg);
}

.skew-lines:after {
	content: '';
	position: absolute;
	left: 39px;
	top: 8px;
	width: 20px;
	height: 27px;
	background: #ff5700;
	transform: skewX(-35deg);
	-webkit-transform: skewX(-35deg);
	-moz-transform: skewX(-35deg);
	-ms-transform: skewX(-35deg);
	-o-transform: skewX(-35deg);
}

.image-container {
	border: 2px solid #ff5700;
	border-radius: 10px;
}
.image-container img {
	width: 100%;
}

section.dark {
	background-color: #1c1c1c;
}

.sec-title {
	position: relative;
	margin-bottom: 30px;
	padding-bottom: 12px;
	border-bottom: 1px solid #e0e0e0;
}

.sec-title h2 {
	text-transform: capitalize;
	font-weight: 500;
}

.cta.btn {
	background-color: #ff5700;
	border: 1px solid #ee4f00;
	transition: all 300ms ease-out;
}

.cta.btn:hover {
	background-color: #232323;
	border: 1px solid #ff5700;
	color: white;
}

.help-block {
	color: #fa1a1a;
	font-size: 14px;
}

.alert-success {
	color: #6ada6c;
	background-color: #272727;
	border-color: #6ada6c;
}
.alert-danger {
	color: #fa1a1a;
	background-color: #272727;
	border-color: #fa1a1a;
}
.alert-info {
	color: #42a0f8;
	background-color: #272727;
	border-color: #42a0f8;
}

/*** 
====================================================================
	Main Header style
====================================================================
***/

.main-header {
	position: relative;
	width: 100%;
	left: 0px;
	top: 0px;
	z-index: 999;
	background: #ffffff;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.main-header .header-top {
	position: relative;
	padding: 20px 0px;
	background: #000000;
	background-image: url('../images/top-header-bgd.jpg');
	background-repeat: no-repeat;
	background-size: cover;

	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.fixed-top-header .header-top {
	padding: 0px 0px;
}

.fixed-top-header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.main-header .logo {
	position: relative;
	padding: 20px 15px;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

/* .fixed-top-header .logo {
	padding: 0px 0px;
} */

.main-header .logo img {
	position: relative;
	display: inline-block;
	max-width: 100%;
	padding: 0px 0px;
}

.main-header .header-top .medium {
	width: 150px;
}

.main-header .header-lower {
	position: relative;
	padding: 0px 0px;
	background: #232323;
}

.main-menu {
	position: relative;
	z-index: 5;
}

.main-menu .navbar-collapse {
	padding: 0px 0px;
}

.main-menu .navbar-collapse > ul > li {
	position: relative;
	float: left;
}

.main-menu .navbar-collapse > ul > li.dropdown > a:after {
	font-family: 'FontAwesome';
	content: '\f107';
	position: absolute;
	right: 15px;
	top: 18px;
	width: 10px;
	height: 20px;
	display: block;
	color: #ffffff;
	line-height: 20px;
	font-size: 16px;
	text-align: center;
	z-index: 5;
}

.main-menu .navbar-collapse > ul > li > a {
	position: relative;
	display: block;
	font-size: 14px;
	line-height: 24px;
	padding: 16px 15px;
	color: #ffffff;
	background: none;
	overflow: hidden;
	text-transform: uppercase;
	transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
}

.main-menu .navbar-collapse > ul > li.dropdown > a {
	padding: 16px 40px 16px 15px;
}

.main-menu .navbar-collapse > ul > li:hover > a,
.main-menu .navbar-collapse > ul > li > a.current,
.main-menu .navbar-collapse > ul > li.current-menu-item > a {
	color: #ffffff;
	background: #ff5700;
}

.main-menu .navbar-collapse > ul > li > ul {
	position: absolute;
	display: block;
	width: 210px;
	top: 120%;
	left: 0;
	opacity: 0;
	visibility: hidden;
	background: #ff5700;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0px 0px 5px 5px;
	-webkit-border-radius: 0px 0px 5px 5px;
	-ms-border-radius: 0px 0px 5px 5px;
	-moz-border-radius: 0px 0px 5px 5px;
	-o-border-radius: 0px 0px 5px 5px;
}

.main-menu .navbar-collapse > ul > li:hover > ul {
	top: 100%;
	opacity: 1;
	visibility: visible;
	transition: all 400ms ease 100ms;
	-moz-transition: all 400ms ease 100ms;
	-webkit-transition: all 400ms ease 100ms;
	-ms-transition: all 400ms ease 100ms;
	-o-transition: all 400ms ease 100ms;
}

.main-menu .navbar-collapse > ul > li > ul > li {
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.main-menu .navbar-collapse > ul > li > ul > li:last-child {
	border: none;
}

.main-menu .navbar-collapse > ul > li > ul > li > a {
	display: block;
	line-height: 24px;
	font-size: 14px;
	color: #ffffff;
	padding: 7px 15px;
	background: none;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
}

.main-menu .navbar-collapse > ul > li > ul > li:hover > a {
	background: rgba(255, 255, 255, 0.3);
	padding-left: 20px;
}

.main-menu .navbar-collapse > ul > li > ul > li > ul {
	position: absolute;
	display: block;
	width: 210px;
	top: -1px;
	left: 120%;
	opacity: 0;
	visibility: hidden;
	background: #fb4848;
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0px 5px 5px 5px;
	-webkit-border-radius: 0px 5px 5px 5px;
	-ms-border-radius: 0px 5px 5px 5px;
	-moz-border-radius: 0px 5px 5px 5px;
	-o-border-radius: 0px 5px 5px 5px;
}

.main-menu .navbar-collapse > ul > li > ul > li:hover > ul {
	opacity: 1;
	left: 100%;
	visibility: visible;
	transition: all 400ms ease 100ms;
	-moz-transition: all 400ms ease 100ms;
	-webkit-transition: all 400ms ease 100ms;
	-ms-transition: all 400ms ease 100ms;
	-o-transition: all 400ms ease 100ms;
}

.main-menu .navbar-collapse > ul > li > ul > li > ul > li {
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.main-menu .navbar-collapse > ul > li > ul > li > ul > li:last-child {
	border: none;
}

.main-menu .navbar-collapse > ul > li > ul > li > ul > li > a {
	display: block;
	line-height: 24px;
	font-size: 14px;
	color: #ffffff;
	padding: 7px 15px;
	background: none;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
}

.main-menu .navbar-collapse > ul > li > ul > li > ul > li:hover > a {
	background: rgba(255, 255, 255, 0.3);
	padding-left: 20px;
}

/**
* Header Top Infos Styles.
**/

.header-top-infos > ul > li {
	float: right;
	/* width: 50%; */
	border-left: 1px solid #d3d3d3;
	padding: 0 18px;
}
.header-top-infos > ul > li:last-child {
	border: none;
}
.header-top-infos {
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	margin-top: 15px;
}
.header-top-infos p {
	margin-bottom: 5px;
}
.header-top-infos li div img {
	float: left;
	margin-right: 10px;
	margin-top: 5px;
}
.header-top-infos li div p {
	float: left;
}

.header-lower .social-links {
	position: relative;
	padding-top: 3px;
	/* text-align: right !important; */
}

.header-lower .social-links a {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 32px;
	text-align: center;
	background: #2a323a;
	color: #ffffff;
	font-size: 16px;
	line-height: 32px;
	margin: 9px 2px 0px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	-moz-border-radius: 3px;
	transition: all 500ms ease-out;
	-webkit-transition: all 500ms ease-out;
	-ms-transition: all 500ms ease-out;
	-o-transition: all 500ms ease-out;
	-moz-transition: all 500ms ease-out;
}

.header-lower .social-links a:hover {
	background: #fb4848;
}

/***
====================================================================
	Main Slider style
====================================================================
***/

.main-slider {
	position: relative;
	color: #ffffff;
	font-size: 15px;
}

.main-slider .container {
	padding: 30px;
}

.main-slider h2 {
	font-size: 36px;
	line-height: 1.2em;
	font-weight: 700;
	color: #ff5700;
}

.main-slider .text-wrapper {
	/* font-size: 36px; */
	margin-top: 70px;
	margin-left: auto;
	padding: 20px;
}

.main-slider .banner img {
	width: 75%;
}

/*** 
====================================================================
	Home Page Sections
====================================================================
 ***/
.about-us,
.services,
.evaluate,
.gallery {
	position: relative;
	margin-top: 30px;
	padding: 100px 0px;
}

h2 {
	font-size: 28px;
	line-height: 1.3em;
}

.title-box h4 {
	font-size: 18px;
	line-height: 1.6em;
}

.text-content {
	position: relative;
	margin-bottom: 20px;
}
.text-content .text {
	font-size: 14px;
}

/*************
SERVICES SECTION
*************/
section .box-container {
	position: relative;
	margin-bottom: 25px;
}

section .box-container .tab-link {
	position: relative;
	display: block;
	border: 1px solid#ff5700;
	border-radius: 20px;
	background-color: #1c1c1c;
	padding: 16px 20px 16px 90px;
	font-size: 28px;
	color: #ff5700;
}

section .box-container .tab-link.active-btn,
section .box-container .tab-link:hover {
	color: #ffffff;
	border-color: #ffffff;
}

section .box-container .tab-link.active-btn .icon,
section .box-container .tab-link:hover .icon {
	color: #ffffff;
}

section .box-container .tab-link .icon {
	position: absolute;
	left: 20px;
	top: 24px;
	width: 70px;
	height: 70px;
	color: #ff5700;
	font-size: 42px;
}

section .box-container .tab-link h4 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 0px;
}

section .box-container .tab-link p {
	font-size: 14px;
	color: #a7a7a7;
	margin-bottom: 0px;
}

/*** 
====================================================================
	Main Footer
====================================================================
 ***/
.main-footer {
	position: relative;
	/* margin-top: 50px; */
	background: #232323;
}

.main-footer .footer-upper {
	position: relative;
	padding: 100px 0px 50px;
}

.main-footer .footer-upper .col-md-3 {
	position: relative;
	margin-bottom: 25px;
}

.main-footer .footer-upper .footer-widget {
	position: relative;
	max-width: 600px;
}

.main-footer .footer-upper h3 {
	position: relative;
	font-size: 18px;
	margin-bottom: 25px;
	color: #ffffff;
	text-transform: uppercase;
	font-weight: 800;
}

.main-footer .footer-upper .text {
	position: relative;
	font-size: 14px;
	font-family: 'Droid Sans', sans-serif;
	margin-bottom: 10px;
	color: #ffffff;
	line-height: 25px;
}

.main-footer .footer-upper .info {
	position: relative;
	font-size: 14px;
}

.main-footer .footer-upper .info li {
	position: relative;
	margin-bottom: 5px;
	line-height: 1.7em;
	color: #ffffff;
}

.main-footer .footer-upper .info li strong {
	position: relative;
	padding-right: 10px;
	font-weight: 500;
	color: #ffffff;
}

.main-footer .footer-upper span,
.main-footer .footer-upper a {
	color: #ff5700;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-footer .footer-upper p:hover {
	color: #ff7733 !important;
}

.main-footer .footer-upper .links {
	position: relative;
	font-size: 16px;
}

.main-footer .footer-upper .links li {
	position: relative;
	margin-bottom: 10px;
	line-height: 30px;
	overflow: hidden;
}

.main-footer .footer-upper .links li a {
	position: relative;
	display: block;
	color: #ffffff;
	font-size: 14px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-footer .footer-upper .links li a:hover {
	color: #ff5700 !important;
}

.main-footer .footer-upper .links li a i {
	color: #ff5700;
	margin-right: 7px;
	font-size: 17px;
}

.main-footer .footer-bottom {
	position: relative;
	padding: 5px 0px;
	font-size: 14px;
	line-height: 20px;
	background: #1d1d1d;
	color: #ffffff;
}

.main-footer .footer-bottom .copyright {
	padding: 12px 0px;
}

.main-footer .social-links {
	position: relative;
	text-align: right;
	padding-bottom: 6px;
}

.main-footer .social-links a {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 32px;
	text-align: center;
	background: #232323;
	color: #ffffff;
	font-size: 16px;
	line-height: 32px;
	margin: 9px 2px 0px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	-moz-border-radius: 3px;
	transition: all 500ms ease-out;
	-webkit-transition: all 500ms ease-out;
	-ms-transition: all 500ms ease-out;
	-o-transition: all 500ms ease-out;
	-moz-transition: all 500ms ease-out;
}

.main-footer .social-links a:hover {
	background: #ff5700;
}
/*Leaflet Container*/
.leaflet-container {
	height: 100%;
	width: 100%;
}
#map,
#map-canvas {
	height: 270px;
}

/*** 
====================================================================
	Services
====================================================================
 ***/
.tabs-section {
	position: relative;
	padding: 40px 0px 0px;
	/* background: #ffffff; */
}

.tabs-box .buttons-side {
	position: relative;
	float: right !important;
	margin-bottom: 30px;
}

.tabs-box .tab-buttons {
	position: relative;
}

.tabs-box .tab-buttons li {
	position: relative;
	margin-bottom: 15px;
}

.tabs-box .tab-buttons .tab-btn {
	cursor: pointer;
	position: relative;
	display: block;
	background: #272727;
	border: 1px solid #565656;
	border-radius: 10px;
	padding: 20px 20px 20px 90px;
	font-size: 14px;
	color: #ffffff;
}

.tabs-box .tab-buttons .tab-btn * {
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.tabs-box .tab-buttons .tab-btn.active-btn,
.tabs-box .tab-buttons .tab-btn:hover {
	background: #1c1c1c;
	color: #ff5700;
	border-color: #ff5700;
	border-radius: 10px;
}

.tabs-box .tab-buttons .tab-btn .icon {
	position: absolute;
	left: 20px;
	top: 28px;
	width: 70px;
	height: 70px;
	color: #ff5700;
	font-size: 42px;
}

.tabs-box .tab-buttons .tab-btn.active-btn .icon,
.tabs-box .tab-buttons .tab-btn:hover .icon {
	color: #ff5700;
}

.tabs-section .tab-buttons .tab-btn h4 {
	font-size: 16px;
	margin-bottom: 0px;
}

.tabs-box .tab-buttons .tab-btn p {
	font-size: 14px;
	color: #a7a7a7;
	margin-bottom: 0px;
}

.tabs-box .tab-buttons .tab-btn.active-btn p,
.tabs-box .tab-buttons .tab-btn:hover p {
	color: #ffffff;
}

.tabs-box .tabs-content {
	position: relative;
	float: left !important;
}

.tabs-box .tab-title {
	position: relative;
	margin-bottom: 20px;
}

.tabs-box .tab-title h2 {
	font-size: 36px;
	margin-bottom: 10px;
}

.tabs-box .tab-title h3 {
	font-size: 22px;
	color: #ff5700;
	margin-bottom: 0px;
}

.tabs-box .tab .text {
	position: relative;
	margin-bottom: 30px;
	line-height: 1.8em;
	color: #ffffff;
}

.tabs-box .tab .list {
	position: relative;
	margin-bottom: 30px;
	line-height: 1.5em;
}

.tabs-box .tab .list ul li {
	position: relative;
	line-height: 30px;
	padding-left: 30px;
	margin-bottom: 15px;
}

.tabs-box .tab .list ul li:before {
	font-family: 'FontAwesome';
	content: '\f00c';
	position: absolute;
	left: 0px;
	top: 0px;
	width: 20px;
	height: 30px;
	line-height: 30px;
	padding-right: 15px;
	font-size: 18px;
	color: #ff5700;
}

.tabs-box .tab {
	position: relative;
	transition: all 500ms ease-out;
	-webkit-transition: all 500ms ease-out;
	-ms-transition: all 500ms ease-out;
	-o-transition: all 500ms ease-out;
	-moz-transition: all 500ms ease-out;
}

.tabs-box .tab.active-tab {
	left: 0px;
}

/*** 
====================================================================
	Contact 
====================================================================
 ***/

.contact-form {
	position: relative;
}

.contact-form .sec-title {
	margin-bottom: 30px;
}

.contact-form .msg-text {
	margin-bottom: 40px;
}

.contact-form .form-group {
	position: relative;
	display: block;
	padding: 0px 0px;
	margin-bottom: 16px;
}

.contact-form .form-group .form-label {
	display: block;
	margin-bottom: 12px;
	font-weight: 600;
}

.contact-form input[type='text'],
.contact-form input[type='password'],
.contact-form input[type='email'],
.contact-form input[type='tel'],
.contact-form input[type='number'],
.contact-form input[type='file'],
.contact-form input[type='url'],
.contact-form select,
.contact-form textarea {
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 12px 20px;
	font-size: 14px;
	color: #e6e6e6;

	background: #272727;
	border: 1px solid;
	border-color: #999999;
	border-radius: 5px;
	transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
}

.contact-form input[type='text']:focus,
.contact-form input[type='password']:focus,
.contact-form input[type='email']:focus,
.contact-form input[type='tel']:focus,
.contact-form input[type='number']:focus,
.contact-form input[type='file']:focus,
.contact-form input[type='url']:focus,
.contact-form select:focus,
.contact-form textarea:focus,
.contact-form input[type='text']:hover,
.contact-form input[type='password']:hover,
.contact-form input[type='email']:hover,
.contact-form input[type='tel']:hover,
.contact-form input[type='number']:hover,
.contact-form input[type='file']:hover,
.contact-form input[type='url']:hover,
.contact-form select:hover,
.contact-form textarea:hover {
	border-color: #ff5700;
}

.contact-form input.error,
.contact-form textarea.error {
	border-color: #ff0000 !important;
	background: rgba(252, 90, 27, 0.05);
}

.contact-form label.error {
	display: none !important;
}

.contact-form textarea {
	height: 272px;
	resize: none;
}

.contact-form button {
	padding: 10px 20px;
}

/*custom checkbox*/
/* Customize the label (the container) */
.form-group .container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.form-group .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #272727;
	border: 1px solid;
	border-color: #999999;
	border-radius: 5px;
	transition: all 300ms ease-out;
}

/* On mouse-over and When the checkbox is checked, change style */
.form-group .container:hover input ~ .checkmark,
.form-group .container input:checked ~ .checkmark {
	background-color: #1c1c1c;
	border-color: #ff5700;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
	transition: all 300ms ease-out;
}

/* Show the checkmark when checked */
.form-group .container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.form-group .container .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid #ff5700;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.map-location {
	position: relative;
	width: 100%;
	height: 500px;
}

/*** 
====================================================================
	Several
====================================================================
 ***/
.player-wrapper {
	position: relative;
	padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}
